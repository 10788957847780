
import { BlockComponent } from "../../../framework/src/BlockComponent";


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
  }
  
  interface S {
    // Customizable Area Start
    newPassword: string;
    confirmPassword: string
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }
  export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.state={
      // Customizable Area Start
        newPassword: '',
        confirmPassword: '' 
        // Customizable Area End
    }
  }
  // Customizable Area Start
  handleNewPassword = (e: any) => {
    this.setState({ newPassword: e.target.value })
  };

  handleConfirmPassword = (e: any) => {
    this.setState({ confirmPassword: e.target.value })
  };

  passwordLength = () => {
    const lengthRequirement = this.state.newPassword.length >= 8;
    return lengthRequirement
  }

  differentCharacters = () => {
    const characterCombination = /[A-Za-z]/.test(this.state.newPassword) && /d/.test(this.state.newPassword) && /[^A-Za-z0-9]/.test(this.state.newPassword);
    return characterCombination
  }
  upperCaseCheck = () => {
    const uppercaseRequirement = /[A-Z]/.test(this.state.newPassword);
    return uppercaseRequirement
  }

  // Customizable Area End

}