import React from "react";

// Customizable Area Start
import { HeaderLogoImg } from '../../../blocks/landingpage/src/assets';

import {
  Box,
  Button,
  styled,
  Typography,
} from '@mui/material';
// Customizable Area End

import NewPasswordController, {
  Props,
 
} from "./NewPasswordController.web";
import { inValidIcon, validIcon } from "./assets";

export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleSubmitstyle = () => {
    if (this.handleupperCaseCheck() && this.handledifferentCharacters() && this.handlepasswordLength() && this.state.changedPassword==this.state.confirmChangedPassword) {
      return "Btn-style"
    }
    else {
      return "Btn-style-disabled"
    }
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <StyledWrapper>
        <section className='leftBorder'></section>
        <Box className="mainContainer">
          <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
          </Box>
          <Box className="box">
            <Box className="head">
              <Box className='headingContainer'>
              <Typography className="heading">New Temporary Password Created! </Typography>
              <Typography className="subHeading">A new temporary password has been sent to  
                this email <span className="email">a********b@email.com</span>
              </Typography>

              </Box>
              <Box className="input-parent-without-icon">
                <input className='search-input-tag' type="text" />
              </Box>
              <Box className="box1">
                <Typography className="receivePassword">I didn't receive the password,</Typography>
                <Typography className="receivePasswordHighlight">please resend it by email</Typography>
              </Box>
              <Box className="box2">
                <Typography className="receivePassword">Send me the password by</Typography>
                <Typography className="receivePasswordHighlight">SMS instead</Typography>
              </Box>
              <Box className="inputHead" style={{ marginBlock: "20px" }}><Box className="security-text"><Typography className='stateSelect'>Enter New Password</Typography>
              </Box>
                <Box className="input-parent-without-icon">
                  <input type="text" className='search-input-tag' onChange={this.handleChangePassword} data-test-id="handleCurrentPassword" />
                </Box>
              </Box>
              <Box className="inputHead"><Box className="security-text"><Typography className='stateSelect'>Confirm New Password</Typography>
              </Box>
                <Box className="input-parent-without-icon">
                  <input type="text" className='search-input-tag' onChange={this.handleConfirmChangepassword} data-test-id="handleconfirmChangedPassword" />
                </Box>
              </Box>
              <Typography className="newPassword">Rules for setting a new secure password:</Typography>
              <Box className="inputHead">
                <Box className="box3">
                  <img src={this.handlepasswordLength() ? validIcon : inValidIcon} className="validate" />
                  <Typography className={this.handlepasswordLength() ? "receivePassword" : "invalidPassword"}>Use at least 8 characters</Typography>
                </Box>
                <Box className="box3">
                  <img src={this.handledifferentCharacters() ? validIcon : inValidIcon} className="validate" />
                  <Typography className={this.handledifferentCharacters() ? "receivePassword" : "invalidPassword"}>Use combination of different characters</Typography>
                </Box>
                <Box className="box3">
                  <img src={this.handleupperCaseCheck() ? validIcon : inValidIcon} className="validate" />
                  <Typography className={this.handleupperCaseCheck() ? "receivePassword" : "invalidPassword"}>Use at least one uppercase</Typography>
                </Box>
              </Box>
              <Button data-test-id="testSubmit"
                className={this.handleSubmitstyle()}
              // onClick={this.submitBooking}
              >Submit</Button>

            </Box>
          </Box>
          <Box className="callBack">
            <Typography className="receivePassword" >Need help? </Typography>
            <Typography className="receivePasswordHighlight">Call us or request a callback</Typography>
          </Box>
        </Box>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
  "& .leftBorder": { backgroundColor: "#1A4A42", width: 32, height: '100vh', position: 'fixed', borderRadius: '0px 16px 16px 0px', top: 0, left: 0, zIndex: 99999 },
  "& .header": { display: "flex", alignItems: "center", height: "auto", minHeight: "78px", width: "100%", backgroundColor: "#fff" },
  "& .logoImg": { marginLeft: "144px", width: "162px", height: "41.24px" },
  "& .box": { display: "flex", borderRadius: "30px", border: " 1px solid #D6D3D1", maxWidth: "576px", margin: "80px auto 32px", padding: "40px 60px" },
  "& .head": { display: "flex", flexDirection: "column", gap: 12 },
  "& .heading": { fontWeight: 700, fontFamily: "Poppins", fontSize: "20px", color: "#1A4A42" },
  "& .subHeading": { fontWeight: 400, fontFamily: "Poppins", fontSize: "20px", color: "#2C2524" ,textAlign:'center'},
  '& .search-input-tag:hover, .search-input-tag:focus': { border: 'none !important', outline: 'none !important' },
  '& .search-input-tag::placeholder': { fontWeight: "400px", fontSize: "16px", fontFamily: "Poppins", color: "#A8A29E" },
  "& .search-input-tag": { width: '100%', border: 'none', backgroundColor: "transparent" },
  "& .input-parent-without-icon": { position: 'relative', border: '1px solid #E6C79C', borderRadius: 30, overflow: 'hidden', paddingLeft: 16, paddingBlock: 12 },
  "& .box1": { display: "flex", flexDirection: "row", gap: "5px" },
  "& .box2": { display: "flex", flexDirection: "row", gap: "5px", paddingBottom: "32px", borderBottom: "1px solid #D6D3D1" },
  "& .box3": { display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" },
  "& .stateSelect": { textAlign: "start", fontWeight: 700, fontSize: "16px", fontFamily: "Poppins", color: "#2C2524" },
  "& .inputHead": { display: "flex", flexDirection: "column", gap: "4px", flex: "1" },
  "& .receivePassword": { fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#44403C" },
  "& .receivePasswordHighlight": { fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#E46760", textDecoration: "underline" },
  "& .invalidPassword": { fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#E46760" },
  "& .newPassword": { fontFamily: "Poppins", fontWeight: 700, fontSize: "20px", color: "#2C2524" },
  "& .validate": { width: "20px", height: "20px" },
  "& .Btn-style": { background: "#E46760", color: "#FFFAF6", borderRadius: "24px", height: "56px", textTransform: "capitalize", fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", width: '100%', marginTop: 10 },
  '& .Btn-style:hover': { background: "#E46760", color: "#FFFAF6" },
  "& .Button-style-disabled": { background: "#E7E5E4", color: "#A8A29E", borderRadius: "24px", height: "56px", textTransform: "capitalize", fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", width: '100%', marginTop: 10 },
  "& .callBack": { display: "flex", flexDirection: "row", gap: "5px", justifyContent: "center", marginBottom: "40px" },
  "& .headingContainer":{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
"& .email":{
    fontWeight:700,
    
}
});


// Customizable Area End
