export const regularIcon = require("../assets/Regular.png").default;
export const clipBoardIcon = require("../assets/clipboard.png").default;
export const successImage = require("../assets/success_image.png").default;
export const walletImage = require("../assets/wallet.png").default;
export const insuraneOne = require("../assets/article_shortcut1.png").default;
export const insuraneTwo = require("../assets/arrow.png").default;
export const insuraneThree = require("../assets/headset.png").default;
export const callImage = require("../assets/callImage.png").default;
export const tickImage = require("../assets/tick.png").default;
export const emptyImage = require("../assets/empty.png").default;
export const reprocessImage = require("../assets/reprocess.png").default;
export const Morning = require("../assets/Morning.svg").default;
export const Afternoon = require("../assets/Afternoon.svg").default;
export const Night = require("../assets/Night.svg").default;
export const ActiveMorning = require("../assets/ActiveMorning.svg").default;
export const ActiveAfternoon = require("../assets/ActiveAfternoon.svg").default;
export const ActiveNight = require("../assets/ActiveNight.svg").default;
export const PlayIcon = require("../assets/PlayIcon.png").default;
export const MuteIcon = require("../assets/MuteIcon.png").default;
export const OptionIcon = require("../assets/optionIcon.png").default;
export const ShareIcon = require("../assets/ShareIcon.png").default;
export const ScreenSize = require("../assets/screenSizeIcon.png").default;
export const SuccessModal = require("../assets/SuccessModalIcon.png").default;
export const ScheduleCall = require("../assets/ScheduleCall.png").default;
export const AssembleIcon = require("../assets/AssembleIcon.png").default;
export const SearchIcon = require("../assets/SearchIcon.png").default;
export const CheckboxIcon = require("../assets/checkbox.png").default;
export const Checkbox_checked = require("../assets/checkbox_checked.png").default;
export const Disabled_checkbox = require("../assets/Disabled_checkbox.png").default;